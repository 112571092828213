const config = {
  apiGateway: {
    REGION: 'us-west-2',
    INTERNAL_API_URL: 'https://6aub8hgp2m.execute-api.us-west-2.amazonaws.com/prod',
    TARGET_API_URL: 'https://4h4y1tk2dj.execute-api.us-west-2.amazonaws.com/api',
    TEAMS_APP_URL: 'https://teams.emeco.forwoodsafety.com/teams',
    QS_DASHBOARD_URL: '',
    CLIENT_TIMEZONE: 'America/Sao_Paulo'
  },
  reactApp: {
    VERSION: 'targets-1.8.0',
    HOSTNAME: 'https://targets.emeco.forwoodsafety.com'
  }
};

export default config;